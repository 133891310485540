<template>
  <v-container fluid>
    <v-card class="elevation-0">
      <CategoryTitle :category="category" class="mt-0 mb-6 my-lg-6" />
      <!-- <v-row no-gutters>
        <v-col cols="12" sm="4"></v-col>
      </v-row> -->
      <div class="text-h4">
        INSERISCI LA NUOVA PASSWORD
      </div>
      <v-card-text class="pb-0">
        <v-form
          ref="form"
          class="reset-password"
          v-model="valid"
          :lazy-validation="lazy"
        >
          <v-text-field
            v-model="password"
            label="Password *"
            :append-icon="showPassword ? '$eye' : '$eyeOff'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordRules"
            @click:append="toggleShowPassword"
            required
            outlined
            dense
          ></v-text-field>
          <v-text-field
            v-model="passwordConfirm"
            label="Conferma Password *"
            :append-icon="showPassword ? '$eye' : '$eyeOff'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordConfirmRules"
            @click:append="toggleShowPassword"
            required
            outlined
            dense
            clearable
          ></v-text-field>
        </v-form>
        <ResponseMessage :response="response" />
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="handleResetPassword"
          class="reset-button"
          color="primary"
          min-width="200"
          large
          depressed
          :disabled="!valid || password == null || password != passwordConfirm"
          >{{ $t("login.submitResetPassword") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<style scoped>
.reset-password {
  width: 350px;
}
</style>
<script>
import RegistrationService from "@/service/poliCustomService";
import ResponseMessage from "@/components/ResponseMessage";
import CategoryTitle from "@/components/category/CategoryTitle";
import categoryMixin from "~/mixins/category";
import { mapActions } from "vuex";
import { req } from "~/validator/commons";
import { requiredValue } from "~/validator/validationRules";

export default {
  name: "ResetPassword",
  components: { ResponseMessage, CategoryTitle },
  mixins: [categoryMixin],
  data() {
    return {
      showPassword: false,
      password: null,
      passwordConfirm: null,
      valid: true,
      lazy: true,
      passwordRules: [
        requiredValue("Digitare la password"),
        this.customPasswordRule()
      ],
      passwordConfirmRules: [
        v => !!v || "Confermare la password",
        v => v === this.password || "Le password non coincidono"
      ],
      errors: [],
      invalid: null,
      code: null,
      response: {},
      showForm: true
    };
  },
  methods: {
    customPasswordRule(error) {
      // eslint-disable-next-line no-unused-vars
      var errorMessage = error
        ? error
        : "La password deve essere lunga almeno 8 caratteri, contenere maiuscole, minuscole e almeno un numero";
      return function(v) {
        const errorMessage =
          "La password deve contenere almeno 8 caratteri, una maiuscola, una minuscola e un numero";
        const minLength = /.{8,}/.test(v);
        const hasUppercase = /[A-Z]/.test(v);
        const hasLowercase = /[a-z]/.test(v);
        const hasNumber = /[0-9]/.test(v);
        return (
          // eslint-disable-next-line no-undef
          !req(v) ||
          (minLength && hasUppercase && hasLowercase && hasNumber) ||
          errorMessage
        );
      };
    },
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    ...mapActions({ loadCart: "cart/loadCart" }),
    async handleResetPassword() {
      try {
        this.loading = true;
        const response = await RegistrationService.recoverPasswordConfirm(
          this.code,
          this.password
        );
        this.response = response.response;
        if (response.response.status == 0) {
          this.loadCart();
          this.$router.push({ name: "ResetPasswordCompleted" });
        }
      } catch (err) {
        if (err.response) {
          this.response = err.response.data.response;
        }
      } finally {
        this.loading = false;
        this.showForm = false;
      }
    },
    getQueryParams() {
      this.code = this.$route.query.code;
    }
  },
  created() {
    this.getQueryParams();
  }
};
</script>

<style></style>
