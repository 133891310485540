import axios from "~/plugins/axios";
export default {
  getCityList() {
    return axios
      .get("/ebsn/api/poli-registration/city_list")
      .then(data => data.data.data.values)
      .catch(error => error);
  },

  confirmRegistration(user) {
    let url = "/ebsn/api/poli-registration/confirm_dati_duplicard";
    return axios.post(url, user).then(response => {
      return response.data;
    });
    //.catch(err => Promise.reject(err.response.data.response));
  },

  checkCard(user) {
    return axios
      .post("/ebsn/api/poli-registration/check_user", user)
      .then(data => data.data.response)
      .catch(error => error);
  },

  confirmDoubleOptin(token) {
    return axios
      .post("/ebsn/api/poli-registration/confirm_double_optin", null, {
        params: { code: token }
      })
      .then(data => data.data.response)
      .catch(error => error);
  },

  updateUserDetail(user) {
    let url = "/ebsn/api/poli-registration/update_user_detail";
    return axios
      .post(url, user)
      .then(response => {
        return response.data.data.user;
      })
      .catch(err => {
        console.log(err);
      });
  },

  updatePassword(oldPassword, newPassword) {
    let url = "/ebsn/api/poli-registration/update_password";
    return axios
      .get(url, {
        params: { old_password: oldPassword, new_password: newPassword }
      })
      .then(response => {
        return response.data;
      })
      .catch(err => err);
  },

  recoverPassword(email) {
    let url = "/ebsn/api/poli-registration/recover_password";
    return axios
      .get(url, {
        params: { email: email }
      })
      .then(response => {
        return response.data;
      })
      .catch(err => err);
  },

  recoverPasswordConfirm(code, password) {
    let url = "/ebsn/api/poli-registration/recover_password_confirm";
    return axios
      .get(url, {
        params: { code: code, pwd: password }
      })
      .then(response => {
        return response.data;
      })
      .catch(err => err);
  }
};
